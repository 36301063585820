import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
const layoutProps = {};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Dr. Enzo Quint was a professor at `}<a parentName="p" {...{
        "href": "/The%20Basentia%20Omnia",
        "title": "The Basentia Omnia"
      }}>{`The Basentia`}</a>{` and a `}<a parentName="p" {...{
        "href": "/Acronist#stonesingers",
        "title": "Acronist"
      }}>{`Stonesinger`}</a>{`,  well known for his research into the scientific implications of the `}<a parentName="p" {...{
        "href": "/Thread",
        "title": "Thread"
      }}>{`Thread`}</a>{` and how it relates to the `}<a parentName="p" {...{
        "href": "/The%20Luminary",
        "title": "The Luminary"
      }}>{`The Luminary`}</a>{`.`}</p>
    <h2>{`Disappearance`}</h2>
    <p>{`Quint went missing after apparently recording six tinwheels regarding new research he had gathered.`}</p>
    <h2>{`Recordings`}</h2>
    <h3>{`The Sixth Tinwheel`}</h3>
    <p>{``}<a parentName="p" {...{
        "href": "/The%20Sixth%20Tinwheel",
        "title": "The Sixth Tinwheel"
      }}>{`The Sixth Tinwheel`}</a>{` is the last that Enzo recorded, detailing his last known whereabouts and clues to what might have become of him.`}</p>
    <h3>{`The First Tinwheel`}</h3>
    <p>{``}<a parentName="p" {...{
        "href": "/The%20First%20Tinwheel",
        "title": "The First Tinwheel"
      }}>{`The First Tinwheel`}</a>{` is the first that Enzo recorded, detailing the beginnings of his investigation into some of his findings regarding `}<a parentName="p" {...{
        "href": "/Thread",
        "title": "Thread"
      }}>{`Thread`}</a>{` severance.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      